<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="tableImpr"
        title="개선 목록"
        :columns="gridImpr.columns"
        :editable="editable && !disabled"
        :data="data.dailyImproveModelList"
        :merge="gridImpr.merge"
        :gridHeight="gridImpr.height"
        :usePaging="false"
        :isExcelDown="false"
        :filtering="false"
        :isTitle="true"
        :gridHeightAuto="true"
        :columnSetting="false"
        @linkClick="linkClick"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'customDetail'">
            <q-btn
              flat
              size="12px"
              color="orange"
              icon="chevron_right"
              @click="rowClickDetail(props.row, props.pageIndex)" />
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="작업요청 목록"
        :columns="gridPlan.columns"
        :data="gridPlan.data"
        :usePaging="false"
        :isExcelDown="false"
        :filtering="false"
        :isTitle="true"
        :gridHeightAuto="true"
        :columnSetting="false"
        @linkClick="linkClick2"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="정비요청" icon="add" @btnClicked="openPop" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props }">
          <template v-if="props.row['woWorkReqStepName']">
            <q-chip
              :color="getColors(props.row.woWorkReqStepCd)"
              text-color="white">
              {{props.row.woWorkReqStepName}}
            </q-chip>
          </template>
        </template>
      </c-table>
    </div>
    <q-dialog v-model="dialogDetail" :position="positionDetail">
      <q-card style="width: 100%">
        <q-linear-progress :value="1" color="pink" />
        <q-card-section class="row">
          <div class="col-12 text-weight-bold-dailog" v-text="selectedRow.checkItemName"></div>
          <div class="col-4"><div class="mini-dailog-title">설비명</div></div>
          <div class="col-8" v-text="selectedRow.equipmentName"></div>
          <div class="col-4"><div class="mini-dailog-title">부품</div></div>
          <div class="col-8" v-text="selectedRow.checkItemPartName"></div>
          <div class="col-4"><div class="mini-dailog-title">점검방법</div></div>
          <div class="col-8" v-text="selectedRow.checkItemMethod"></div>
          <div class="col-4"><div class="mini-dailog-title">점검기준</div></div>
          <div class="col-8" v-text="selectedRow.checkStandard"></div>
          <div class="col-4"><div class="mini-dailog-title">점검일</div></div>
          <div class="col-8" v-text="selectedRow.checkDate"></div>

          <div class="col-12 mini-dailog-btn-impr">
            <q-btn-group outline>
              <c-btn
                :showLoading="false"
                label="닫기"
                icon="close"
                @btnClicked="dialogClose"
              />
            </q-btn-group>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'preventive-check-impr',
  props: {
    data: {
      type: Object,
      default: () => ({
        dailyCheckId: '',  // 열화점검 일련 번호
        checkStatusCd: '',
        dailyName: '',
        utlityFlag: '',
        dailyImproveModelList: [],
      }),
    },
  },
  data() {
    return {
      dialogDetail: false,
      positionDetail: 'bottom',
      selectedRow: {},
      selectedRowIdx: -1,
      editable: true,
      gridImpr: {
        columns: [
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            type: 'link',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: '진행상태',
            align: 'center',
            style: 'width:100px',
            sortable: false
          },
          {
            name: 'customDetail',
            field: 'customDetail',
            label: '',
            align: 'center',
            type: 'custom',
            style: 'width:40px',
            sortable: false
          },
          // {
          //   name: 'checkItemMethod',
          //   field: 'checkItemMethod',
          //   label: '점검방법',
          //   align: 'center',
          //   style: 'width:150px',
          //   sortable: false,
          // },
          // {
          //   name: 'checkStandard',
          //   field: 'checkStandard',
          //   label: '점검기준',
          //   align: 'center',
          //   style: 'width:150px',
          //   sortable: false,
          // },
          // {
          //   name: 'checkDate',
          //   field: 'checkDate',
          //   label: '점검일',
          //   align: 'center',
          //   style: 'width:80px',
          //   sortable: false,
          // },
          // {
          //   name: 'improveRequest',
          //   field: 'improveRequest',
          //   label: '요청부서정보',
          //   align: 'left',
          //   style: 'width:200px',
          //   sortable: true,
          // },
          // {
          //   name: 'actionCompleteRequestDate',
          //   field: 'actionCompleteRequestDate',
          //   label: '조치완료요청일',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
          // {
          //   name: 'actionDeptName',
          //   field: 'actionDeptName',
          //   label: '조치부서',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
        ],
        
        data: [],
        height: '300px'
      },
      gridPlan: {
        columns: [
          // {
          //   fix: true,
          //   name: 'plantName',
          //   field: 'plantName',
          //   label: '사업장',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
          {
            fix: true,
            name: 'woWorkReqStepName',
            field: 'woWorkReqStepName',
            label: '진행상태',
            align: 'center',
            type: 'custom',
            style: 'width:100px',
          },
          // {
          //   fix: true,
          //   name: 'emergencyFlagName',
          //   field: 'emergencyFlagName',
          //   label: '구분',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width:50px',
          // },
          {
            fix: true,
            name: 'woRequestName',
            field: 'woRequestName',
            label: '작업명',
            align: 'left',
            sortable: true,
            style: 'width:350px',
            type: 'link'
          },
          // {
          //   name: 'equipmentNo',
          //   field: 'equipmentNo',
          //   label: '설비Tag',
          //   align: 'center',
          //   style: 'width:150px',
          //   sortable: true,
          // },
          // {
          //   name: 'funcLocationName',
          //   field: 'funcLocationName',
          //   label: '기능위치',
          //   align: 'center',
          //   style: 'width:150px',
          //   sortable: true,
          // },
          // {
          //   name: 'deptName',
          //   field: 'deptName',
          //   label: '작업요청 부서',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
          // {
          //   name: 'deptNames',
          //   field: 'deptNames',
          //   label: '정비오더 발행부서',
          //   align: 'center',
          //   style: 'width:200px',
          //   sortable: true,
          // },
          // {
          //   name: 'woWorkTypeName',
          //   field: 'woWorkTypeName',
          //   label: '작업유형',
          //   align: 'center',
          //   style: 'width:120px',
          //   sortable: true,
          // },
          // {
          //   name: 'woRequestDt',
          //   field: 'woRequestDt',
          //   label: '요청일',
          //   align: 'center',
          //   style: 'width:120px',
          //   sortable: true,
          // },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        popupParam: {},
        closeCallback: null,
      },
      isSave: false,
      checkCdItems: [], // 검사결과
      getUrl: '',
      items: [],
      getStdUrl: '',
      insertUrl: '',
      deleteUrl: '',
      colorItems: [],
      component: null,
    };
    
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId)
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.wod.workreq.list.url;

      this.getWorkOrderList();
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;

      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.isFull = false;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    getWorkOrderList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        dailyCheckId: this.data.dailyCheckId
      };
      this.$http.request((_result) => {
        this.gridPlan.data = _result.data;
      },);
    },
    linkClick2(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '작업요청 상세';
      this.popupOptions.param = {
        woRequestId: row ? row.woRequestId : '',
        plantCd: row ? row.plantCd : '',
        dailyCheckId: this.data.dailyCheckId
      };
      this.popupOptions.target = () => import(`${'@/pages/wod/workOrderRequestDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getWorkOrderList();
    },
    getColors(_code) {
      var returnColor = '';
      switch(_code) {
        case 'WWRSC00001': // 작성중
          returnColor = 'orange';
          break;
        case 'WWRSC00002': // 승인요청중
          returnColor = 'purple';
          break;
        case 'WWRSC00003': // 승인완료
          returnColor = 'green';
          break;
        case 'WWRSC00009': // 접수 반려
          returnColor = 'red';
          break;
        case 'WWRSC00010': // 접수 승인
          returnColor = 'blue';
          break;
      }

      return returnColor;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
        let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
        if (splitId && splitId.length > 0) {
          let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
          splitStep[idIndex] = result.col1.ibmStepName;
          this.requestImprRow.ibmStepNames = splitStep.toString();
        }
        this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
      }
      this.$emit('getDetail');
    },
    rowClickDetail(_row, _idx) {
      this.dialogDetail = true;
      this.selectedRow = _row;
      this.selectedRowIdx = _idx;
    },
    dialogClose() {
      this.dialogDetail = false;
    },
  }
};
</script>