var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "tableImpr",
            attrs: {
              title: "개선 목록",
              columns: _vm.gridImpr.columns,
              editable: _vm.editable && !_vm.disabled,
              data: _vm.data.dailyImproveModelList,
              merge: _vm.gridImpr.merge,
              gridHeight: _vm.gridImpr.height,
              usePaging: false,
              isExcelDown: false,
              filtering: false,
              isTitle: true,
              gridHeightAuto: true,
              columnSetting: false,
            },
            on: { linkClick: _vm.linkClick },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props, col }) {
                  return [
                    col.name === "customDetail"
                      ? [
                          _c("q-btn", {
                            attrs: {
                              flat: "",
                              size: "12px",
                              color: "orange",
                              icon: "chevron_right",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.rowClickDetail(
                                  props.row,
                                  props.pageIndex
                                )
                              },
                            },
                          }),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "작업요청 목록",
                columns: _vm.gridPlan.columns,
                data: _vm.gridPlan.data,
                usePaging: false,
                isExcelDown: false,
                filtering: false,
                isTitle: true,
                gridHeightAuto: true,
                columnSetting: false,
              },
              on: { linkClick: _vm.linkClick2 },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props }) {
                    return [
                      props.row["woWorkReqStepName"]
                        ? [
                            _c(
                              "q-chip",
                              {
                                attrs: {
                                  color: _vm.getColors(
                                    props.row.woWorkReqStepCd
                                  ),
                                  "text-color": "white",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(props.row.woWorkReqStepName) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "정비요청", icon: "add" },
                            on: { btnClicked: _vm.openPop },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "q-dialog",
        {
          attrs: { position: _vm.positionDetail },
          model: {
            value: _vm.dialogDetail,
            callback: function ($$v) {
              _vm.dialogDetail = $$v
            },
            expression: "dialogDetail",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { width: "100%" } },
            [
              _c("q-linear-progress", { attrs: { value: 1, color: "pink" } }),
              _c("q-card-section", { staticClass: "row" }, [
                _c("div", {
                  staticClass: "col-12 text-weight-bold-dailog",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.checkItemName),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("설비명"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.equipmentName),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("부품"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.checkItemPartName),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("점검방법"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.checkItemMethod),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("점검기준"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.checkStandard),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("점검일"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: { textContent: _vm._s(_vm.selectedRow.checkDate) },
                }),
                _c(
                  "div",
                  { staticClass: "col-12 mini-dailog-btn-impr" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: {
                            showLoading: false,
                            label: "닫기",
                            icon: "close",
                          },
                          on: { btnClicked: _vm.dialogClose },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }